<template>
    <v-flex>
        <v-container fluid class="pt-0 mt-5">
            <v-layout fill-height row wrap justify-center align-center>
                <v-flex mx-12 lg-12 md-12>
                    <v-flex>
                        <v-card class="mb-3">
                            <v-card-title>
                                Total Quasar Value
                            </v-card-title>
                        </v-card>
                    </v-flex>
                    <v-btn @click="getKrakenFuturesLog()">测试按钮</v-btn>
                    <!-- 绿色弹窗 -->
                    <v-alert :dismissible="false" class="green white--text" dark border="left">
                        <v-flex class="d-flex justify-space-around">
                            <v-btn tile small outlined @click="symbol='BTC'">BTCUSD</v-btn>
                            <v-btn tile small outlined @click="symbol='ETH'">ETHUSD</v-btn>
                            <v-btn tile small outlined @click="symbol='BCH'">BCHUSD</v-btn>
                            <v-btn tile small outlined @click="symbol='LTC'">LTCUSD</v-btn>
                            <v-btn tile small outlined @click="symbol='XRP'">XRPUSD</v-btn>
                        </v-flex>
                    </v-alert>
                    <v-flex>
                        <el-table :data="symbol == 'BTC' ? btcDataList : (symbol == 'ETH' ? ethDataList : ( symbol == 'BCH' ? bchDataList : (symbol == 'LTC' ? ltcDataList : xrpDataList)))">
                            <el-table-column label="Assets">
                                <template slot-scope="scope">
                                    <span>{{scope.row.dateTime}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column :label="symbol">
                                <el-table-column label="Spot">
                                    <el-table-column label="Kraken Spot Acc't">
                                        <el-table-column :label="symbol">
                                            <el-table-column label="现货">
                                                <span>0.0</span>
                                            </el-table-column>
                                            <el-table-column label="期货">
                                                <template slot-scope="scope">
                                                    <span :class=" Number(toNonExponential(scope.row.change).toFixed(8)) == 0 ? '' :( Number(toNonExponential(scope.row.change).toFixed(8)) > 0 ? 'green--text' : 'red--text') ">{{ (toNonExponential(scope.row.change).toFixed(8)) }}</span>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="USD">
                                                <template slot-scope="scope">
                                                    <span :class="Number((toNonExponential(scope.row.change) * scope.row.close).toFixed(2)) == 0 ? '' : ( Number((toNonExponential(scope.row.change) * scope.row.close).toFixed(2)) > 0 ? 'green--text' : 'red--text' )"> $ {{ (toNonExponential(scope.row.change) * scope.row.close).toFixed(2)}}</span>
                                                </template>
                                            </el-table-column>
                                        </el-table-column>
                                        <el-table-column :label="symbol + ' Close Price'">
                                            <template slot-scope="scope">
                                                <span> $ {{ scope.row.close}}</span>
                                            </template>
                                        </el-table-column>
                                    </el-table-column>
                                    <el-table-column label="Trading MR">
                                        <el-table-column label="Hedged">
                                            <template slot-scope="scope">
                                                <span> $ {{ (toNonExponential(scope.row.change) * scope.row.close).toFixed(2)}}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="Un-Hedged"></el-table-column>
                                    </el-table-column>
                                </el-table-column>
                                <el-table-column label="Futures">
                                    <el-table-column label="Trading MR">
                                        <el-table-column label="Short"></el-table-column>
                                        <el-table-column label="Unrealized P/L"></el-table-column>
                                        <el-table-column label="Unrealized P/L $"></el-table-column>
                                        <el-table-column label="Realized P/L"></el-table-column>
                                        <el-table-column label="Realized P/L $"></el-table-column>
                                    </el-table-column>
                                    <el-table-column label="Trading Positions">
                                        <el-table-column label="Position"></el-table-column>
                                        <el-table-column :label="'Unrealized ' + symbol + ' P/L'"></el-table-column>
                                        <el-table-column :label="'Unrealized ' + symbol + ' P/L $'"></el-table-column>
                                        <el-table-column :label="'Realized ' + symbol + ' P/L'"></el-table-column>
                                        <el-table-column :label="'Realized ' + symbol + ' P/L $'"></el-table-column>
                                    </el-table-column>
                                </el-table-column>
                                <el-table-column :label="symbol + ' Sybtotal'">
                                    <el-table-column label="in USD"></el-table-column>
                                </el-table-column>
                            </el-table-column>
                        </el-table>
                    </v-flex>
                </v-flex>
            </v-layout>
        </v-container>
    </v-flex>
</template>

<script>
    import Vue from 'vue';
    import config from '../../config';
    import { mapGetters } from "vuex";
    export default {
        data() {
            return {
                symbol:"ETH",
                pageNo:2,
                pageSize:10,
                totalElements: 0,
                totalPage: 0,
                dateTime:1,
                btcDataList:[],
                ethDataList:[],
                bchDataList:[],
                ltcDataList:[],
                xrpDataList:[],
            }
        },
        computed: {
            ...mapGetters(['user']),
        },
        watch: {
            
        },
        mounted() {
            if (!this.user.token) {
                this.$router.push({ name: 'login' })
            };
        },
        created() {
          
        },
        methods: {
            // 获取Kraken期货日志
            getKrakenFuturesLog(){
                let api = config.baseUrl + '/exchange/kraken/futures/private/getFuturesLog?symbol='+  this.symbol + '&pageNo=' + this.pageNo;
                this.$http.get(api).then(res=>{
                    let data = res.data;
                    if(data.code == 200){
                        switch (this.symbol) {
                            case 'BTC':
                                this.btcDataList = data.result;
                                break;
                            case 'ETH':
                                this.ethDataList = data.result;
                                break;
                            case 'BCH':
                                this.bchDataList = data.result;
                                break;
                            case 'LTC':
                                this.ltcDataList = data.result;
                                break;
                            case 'XRP':
                                this.xrpDataList = data.result;
                                break;    
                            default:
                                break;
                        }
                    }
                })
            },
            //将科学计数法转换为小数
            toNonExponential(num) {
                return new Number(num);
            }
        }
    }
</script>

<style>

</style>